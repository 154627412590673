const { default: fireTrackingEvent } = require('../../vendors/tracking');
const { normalizeFilter } = require('../../utilities/format');

const {
	table,
	tableData,
} = window.forbes['simple-site'];

const isStarRanked = table.isStarRanked;
const filterField = table.filterField.replace(/\s/g, '');
let dropdownField = table.dropdownField;
const rows = document.getElementsByClassName('table-row');

function setFilterAttributes(row) {
	let searchFilterData = '';

	filterField.split(',').forEach((field, index) => {
		const rowField = row.getElementsByClassName(field)[0];

		if (index === 0) {
			searchFilterData = normalizeFilter(rowField?.textContent, true);
		} else {
			searchFilterData += rowField?.textContent ? `,${normalizeFilter(rowField?.textContent, true)}` : '';
		}
	});

	searchFilterData = searchFilterData.replace(/,/g, '/,/');

	const matchingUri = tableData.find((item) => item.uri === row.getAttribute('uri'));

	if (matchingUri) {
		if (isStarRanked) {
			matchingUri.industryRanks?.forEach((element) => {
				row.setAttribute(normalizeFilter(element.industry), element.rank);
			});
		} else {
			row.setAttribute(dropdownField, normalizeFilter(matchingUri[dropdownField]));
		}
	}

	row.setAttribute('searchfilter', searchFilterData);
}

function fireTrackingEventForProfilePageClick(row) {
	function bindClick(href) {
		return () => {
			fireTrackingEvent('click', href);
		};
	}

	row.addEventListener('click', bindClick(row.href));
}

// for setting attributes to the table rows
function setAttributes() {
	for (let i = 0; i < rows.length; i++) {
		const row = rows[i];
		fireTrackingEventForProfilePageClick(row);
		setFilterAttributes(row);
	}
}

function setDropdownField() {
	if (isStarRanked) {
		dropdownField = table.dropdownField.substring(0, table.dropdownField.indexOf('.'));
	}
}

document.addEventListener('DOMContentLoaded', () => {
	setDropdownField();
	setAttributes();
});

module.exports = {
	setAttributes,
};
