import { fireGAEvent } from '../../shared/tracking';

const {
	eventCategory,
	table = {},
} = window.forbes['simple-site'];

const filterField = table.filterField || '';

const premiumPartners = document.getElementsByClassName('premium-partners')[0];

function fireTrackingEvent(eventAction = '', eventLabel = '', eventCategoryArg) {
	const GAData = {
		eventCategory: eventCategoryArg || eventCategory,
		eventAction,
		eventLabel,
	};
	fireGAEvent(GAData);
}

const readMoreImages = document.getElementsByClassName('read-more-content-image');
const readMoreTitles = document.getElementsByClassName('more-content-title');

function readMoreImage(image) {
	return () => {
		const child = ((image.parentNode || {}).nextSibling || {}).firstChild;
		fireTrackingEvent('click', `Featured Article - ${child.innerText} - ${child.href}`);
	};
}

function readMoreTitle(title) {
	return () => {
		fireTrackingEvent('click', `Featured Article - ${title.innerText} - ${title.href}`);
	};
}

function setReadMoreEventListener(elements, type) {
	for (let i = 0; i < elements.length; i++) {
		const element = elements[i];
		if (type === 'image') {
			element.addEventListener('click', readMoreImage(element));
		} else if (type === 'title') {
			element.addEventListener('click', readMoreTitle(element));
		}
	}
}

if (premiumPartners) {
	premiumPartners.addEventListener('click', () => {
		fireTrackingEvent('click', 'Forbes Premium Partner');
	});
}

document.getElementById('filter').addEventListener('click', () => {
	fireTrackingEvent('click', filterField);
});

document.addEventListener('DOMContentLoaded', () => {
	setReadMoreEventListener(readMoreImages, 'image');
	setReadMoreEventListener(readMoreTitles, 'title');
});

export default fireTrackingEvent;
