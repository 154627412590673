import { reflowRows } from './tableRows';
import fireTrackingEvent from '../../vendors/tracking';

const ReactDOM = require('react-dom');
const React = require('react');
const { default: Pagination } = require('@forbes/fbs-components/dist/cjs/components/Pagination/Pagination');

const tableElement = document.getElementById('table');
const tableBlock = document.getElementsByClassName('table')[0];
const rows = document.querySelectorAll('.table-row');
const filterWrapper = document.querySelector('.filters-wrapper');
const input = document.getElementById('filter');
const dropdown = document.getElementById('dropdown');
const navBarHeight = document.querySelector('.header').offsetHeight + 20;
const pageLimit = window.forbes['simple-site']?.table?.pageLimit || 50;

const { accentColor } = window.forbes['simple-site'];

let sortRows;
let sortField;

function getSortRows() {
	if (tableElement.dataset.sortField !== sortField) {
		sortField = tableElement.dataset.sortField;
		sortRows = undefined;
	}
	if (!sortRows) {
		sortRows = document.querySelectorAll('.table-row');
	}
	return sortRows;
}

function getRowsToPaginate() {
	let activeRows;
	if (tableElement.dataset.filter === 'true') {
		activeRows = document.querySelectorAll('.table-row.filter');
	} else if (tableElement.dataset.sort === 'desc') {
		activeRows = getSortRows();
	} else {
		activeRows = rows;
	}
	return Array.from(activeRows);
}

function showOrHideRow(row, hide) {
	if (hide) {
		row.style.display = 'none';
		row.classList.remove('active');
	} else {
		row.style.display = '';
		row.classList.add('active');
	}
}

function paginate(rowsArray = []) {
	const dropDownFilter = dropdown?.options[dropdown?.selectedIndex]?.value;
	const searchFilter = input.value.toLowerCase();
	const isSearchEmpty = searchFilter === '' || searchFilter || dropDownFilter === 'All';

	// based on current page, only show the elements in that range
	if (rowsArray.length > pageLimit) {
		const start = (tableElement.dataset.pageNumber - 1) * pageLimit;
		const end = start + pageLimit;

		for (let x = 0; x < rowsArray.length; x++) {
			const arrayRank = isSearchEmpty || rowsArray[x].rank === undefined ? x : rowsArray[x].rank - 1;
			showOrHideRow(rowsArray[x], arrayRank < start || arrayRank >= end);
		}
	}
}

/**
 * Native scrollTo with callback
 * @param offset - offset to scroll to
 * @param callback - callback function
 */
function scrollTo(offset, callback) {
	const fixedOffset = offset.toFixed();
	const onScroll = () => {
		if (window.pageYOffset.toFixed() === fixedOffset) {
			window.removeEventListener('scroll', onScroll);
			callback();
		}
	};

	window.addEventListener('scroll', onScroll, { passive: true });
	onScroll();
	window.scrollTo({
		top: offset,
		behavior: 'smooth',
	});
}

function scrollCallback() {
	tableBlock.style.height = 'unset';
}

function insertPagination() {
	const rowsArray = getRowsToPaginate();
	paginate(rowsArray);
}

function reflow() {
	insertPagination();
	const oldHeight = tableBlock.offsetHeight;
	tableBlock.style.height = `${oldHeight}px`;

	const rowsArray = getRowsToPaginate();
	paginate(rowsArray);
	setTimeout(() => {
		scrollTo(filterWrapper.offsetTop - navBarHeight, scrollCallback);
	});

	reflowRows();
}

const goToPage = (pageNumber) => {
	tableElement.dataset.pageNumber = pageNumber;
	tableElement.dataset.paginate = true;
	reflow();
};

function renderPagination(length) {
	if (length <= pageLimit) return;
	const paginationProps = {
		dataPerPage: pageLimit,
		dataLength: length,
		isAdvanced: true,
		currentPage: +tableElement.dataset.pageNumber,
		paginationTheme: {
			backgroundColorActive: accentColor,
			contentColorActive: 'white',
		},
		size: 'medium',
		handlePagination: (pageNumber) => {
			fireTrackingEvent('click', `pagination-${pageNumber}`, 'Template Area Interaction');
			goToPage(pageNumber);
			const paginationContainer = document.querySelector('.table-footer');
			ReactDOM.unmountComponentAtNode(paginationContainer);
			renderPagination(length);
		},
	};

	const paginationContainer = document.querySelector('.table-footer');
	ReactDOM.render(<Pagination {...paginationProps} />, paginationContainer);
}

function resetPagination(length) {
	goToPage(1);
	const tableFooter = document.querySelector('.table-footer');
	ReactDOM.unmountComponentAtNode(tableFooter);
	renderPagination(length);
}

document.addEventListener('DOMContentLoaded', () => {
	tableElement.dataset.pageNumber = 1;
	insertPagination();
	const rowsArray = getRowsToPaginate();
	renderPagination(rowsArray.length);
});

export {
	insertPagination,
	reflow,
	getRowsToPaginate,
	resetPagination,
};
